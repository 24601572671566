import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { hostingTheme } from "common/src/theme/hosting";
import { GlobalStyle, ContentWrapper } from "../../containers/Hosting/hosting.style";
import { ResetCSS } from "common/src/assets/css/style";
import Navbar from "../../containers/Hosting/Navbar";
import Footer from "../../containers/Hosting/Footer";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
import { ParallaxProvider } from "react-scroll-parallax";
import SEO from "../../components/seo";
import TopRibbon from "../../containers/Hosting/TopRibbon";

import Fade from "react-reveal/Fade";
import Box from "common/src/components/Box";
import Heading from "common/src/components/Heading";
import Image from "common/src/components/Image";
import Container from "common/src/components/UI/Container";

import { useStaticQuery, graphql, Link } from "gatsby";
import MiscWrapper from "./misc.style";

import Ctasection from "../../containers/Hosting/Ctasection/Casestudy";

import downloadFile from "../../contents/pdfs/Common Mortgage Processing Bottlenecks and their Incredible Costs.pdf";

const MiscThanksSection1 = ({ name, sectionWrapper, row, col, col2, title, data }) => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title=" Thankyou for downloading Common Mortgage Processing Bottlenecks and their Incredible Costs"
          description="Rapid Acceleration Partners"
        />
        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="MiscThanksPage">
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <MiscWrapper className="misc_thanks_wrapper">
            <Box {...sectionWrapper}>
              <Container>
                <Box {...row}>
                  {/* <Box {...col} className="text_content"> */}
                  <Box className="text_content">
                    <Fade left cascade>
                      <Box>
                        <Heading
                          {...title}
                          content="Common Mortgage Processing Bottlenecks and their Incredible Costs"
                        />
                        <a className="btn btn_tertiary thanks" href={downloadFile}>
                          <span className="btn-text">Download Infographic here</span>
                        </a>
                      </Box>
                    </Fade>
                  </Box>
                </Box>
              </Container>
            </Box>
            <Ctasection content="Accelerate Loan Processing Cycles and Achieve Peak Efficiency In Mortgage Processing" />
          </MiscWrapper>
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

MiscThanksSection1.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
};

MiscThanksSection1.defaultProps = {
  sectionWrapper: {
    as: "div",
    id: "misc_thanks",
    minHeight: "400px",
    flexBox: true,
    alignItems: "center",
    flexWrap: "wrap",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    ml: "-15px",
    mr: "-15px",
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: ["100%", "100%", "100%", "49.99%", "49.99%"],
  },
  col2: {
    pr: "15px",
    pl: "15px",
    width: ["100%", "100%", "100%", "49%", "49%"],
  },
  title: {
    fontSize: ["30px", "30px", "35px", "35px", "35px"],
    fontWeight: "bold",
    color: "#0f2137",
    letterSpacing: "0.015em",
    mb: "25px",
    pt: "0",
    textAlign: "center",
    className: "heading",
  },
};

export default MiscThanksSection1;
